<template>
	<div class="application">
		<div
			v-if="!hasAnswer"
			class="application__status">
			<Tag
				value="В обработке"
				class="text-xs"
				severity="info" />
		</div>

		<div class="application__content">
			<h2
				v-if="mainTitle"
				class="application__title">
				{{ mainTitle }}
			</h2>

			<div class="application__text">
				<h3 class="text-xl application__subtitle">Текст заявки</h3>

				<p class="application__date text-base">
					{{ convertIsoToCustomFormat(state.attributes.date) }}
				</p>

				<div class="application__message text-base">{{ state.attributes.reason }}</div>
			</div>

			<div v-if="hasFile">
				<div class="application__date text-base">Прикреплённые файлы</div>

				<div class="flex flex-column gap-3 mt-3">
					<NuxtLink
						v-for="file in state.attributes.file_links"
						:key="file.link"
						class="block"
						:to="file.link"
						external
						target="_blank">
						<Button
							link
							:label="file.name"
							:icon="`pi ${fileExtension(file.ext)} text-xl`" />
					</NuxtLink>
				</div>
			</div>

			<div
				v-if="hasAnswer"
				class="application__text">
				<h3 class="text-xl application__subtitle">Ответ сотрудника RexRent</h3>

				<p class="application__date text-base">
					{{ convertIsoToCustomFormat(state.attributes.answer_date) }}
				</p>

				<div class="application__message text-base">{{ state.attributes.answer }}</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import convertIsoToCustomFormat from "~/utils/global/convert-iso-to-custom-format";
	import type { TApplicationState } from "~/components/global/application/types/TApplicationState";
	import fileExtension from "~/utils/global/file-extension";

	const props = defineProps<{
		state: TApplicationState;
		mainTitle?: string;
	}>();

	const hasAnswer = computed(() => props.state.attributes.answer);
	const hasFile = computed(() => props.state.attributes.file_links?.length);
</script>

<style scoped lang="scss">
	@import "application";
</style>
